import React from "react";
import Video from "./Video";

export default function Gallery() {
    return (
        <div className="gallery--main">
            <div className="gallery--title">My Flights 🛩</div>
            <div className="gallery--videos">
                <Video 
                    title="X-Plane 11: KMDW to KBDL (B737)"
                    date="Sep 12, 2022"
                    link="https://www.youtube.com/watch?v=XFAGF_6rBxM"
                    planemodel="B737-800"
                    airline="Southwest"
                    departure="KMDW"
                    arrival="KBDL"
                />
                <Video
                    title="X-Plane 11: KPWM to KDCA (B737)"
                    date="May 3, 2022"
                    link="https://www.youtube.com/watch?v=RtS84bK0rQg"
                    planemodel="B737-800"
                    airline="United"
                    departure="KPWM"
                    arrival="KDCA"
                />
                <Video
                    title="X-Plane 11: KDCA to KORD (B737)"
                    date="Dec 22, 2021"
                    link="https://www.youtube.com/watch?v=irD8KjPiz3Y"
                    planemodel="B737-800"
                    airline="Southwest"
                    departure="KDCA"
                    arrival="KORD"
                />
                <Video
                    title="X-Plane 11: KATL to KMIA"
                    date="Jun 15, 2021"
                    link="https://www.youtube.com/watch?v=oTbGooYcICU"
                    planemodel="B737-800"
                    airline="Delta"
                    departure="KATL"
                    arrival="KMIA"
                />
                <Video
                    title="X-Plane 11: KLAX to KSFO"
                    date="Mar 20, 2021"
                    link="https://www.youtube.com/watch?v=VBtclpQ1qBo"
                    planemodel="B737-800"
                    airline="United"
                    departure="KLAX"
                    arrival="KSFO"
                />
                <Video
                    title="X-Plane 11: SACO to SAME (Castellano)"
                    date="Oct 25, 2020"
                    link="https://www.youtube.com/watch?v=hwwfaCZVEug"
                    planemodel="B737-800"
                    airline="Aerolíneas Argentinas"
                    departure="SACO"
                    arrival="SAME"
                />
                <Video
                    title="X-Plane 11: KMIA to SBGL (B77L) LANDING AND REPLAYS"
                    date="Sep 2, 2020"
                    link="https://www.youtube.com/watch?v=UW0NvCIDhwE"
                    planemodel="B777-200LR"
                    airline="American Airlines"
                    departure="KMIA"
                    arrival="SBGL"
                />
                <Video
                    title="X-Plane 11: KMIA to SBGL (B77L) PART OF CRUISE"
                    date="Sep 2, 2020"
                    link="https://www.youtube.com/watch?v=Rqb5Qx1WDNQ"
                    planemodel="B777-200LR"
                    airline="American Airlines"
                    departure="KMIA"
                    arrival="SBGL"
                />
                <Video
                    title="X-Plane 11: KMIA to SBGL (B77L) SETUP AND TAKEOFF"
                    date="Sep 2, 2020"
                    link="https://www.youtube.com/watch?v=ewIu-ixRrg8"
                    planemodel="B777-200LR"
                    airline="American Airlines"
                    departure="KMIA"
                    arrival="SBGL"
                />
                <Video
                    title="Microsoft Flight Simulator: VFR DC AND MORE!"
                    date="Aug 18, 2020"
                    link="https://www.youtube.com/watch?v=Jb1M8RCHJ_4"
                    planemodel="Several"
                    airline="Several"
                    departure="KDCA"
                    arrival="KDCA"
                />
                <Video
                    title="X-Plane 11: FALE to FACT (South Africa)"
                    date="Jul 24, 2020"
                    link="https://www.youtube.com/watch?v=5164iRR0FPs"
                    planemodel="B737-800"
                    airline="South African Airways"
                    departure="FADN"
                    arrival="FACT"
                />
                <Video
                    title="X-Plane 11: KDCA to KATL"
                    date="Jul 15, 2020"
                    link="https://www.youtube.com/watch?v=KWAWUmYvVgs"
                    planemodel="B737-800"
                    airline="Delta SkyTeam"
                    departure="KDCA"
                    arrival="KATL"
                />
                <Video
                    title="X-Plane 11: SARP to SABE (Argentina)"
                    date="Jul 13, 2020"
                    link="https://www.youtube.com/watch?v=IA9Zv87RA1U"
                    planemodel="B737-800"
                    airline="Aerolíneas Argentinas"
                    departure="SARP"
                    arrival="SABE"
                />
                <Video
                    title="X-Plane 11: KFLL to KDCA (Crazy Headwinds)"
                    date="Jun 15, 2020"
                    link="https://www.youtube.com/watch?v=hWMmW1aZTEw"
                    planemodel="B737-800"
                    airline="United"
                    departure="KFLL"
                    arrival="KDCA"
                />
                <Video
                    title="X-Plane 11: Cold Start and FMC Tutorial | Zibo 737-800 |"
                    date="Mar 22, 2020"
                    link="https://www.youtube.com/watch?v=97n0rHbKAs0"
                    planemodel="B737-800"
                    airline="American Airlines (Silver)"
                    departure="KDCA"
                    arrival="KFLL"
                />
                <Video
                    title="X-Plane 11: KDCA TO KCVG"
                    date="Nov 28, 2019"
                    link="https://www.youtube.com/watch?v=_rGkmfOkMv8"
                    planemodel="B737-800"
                    airline="Family Guy Livery"
                    departure="KDCA"
                    arrival="KCVG"
                />
                <Video
                    title="X-Plane 11: KDCA to KJFK"
                    date="Jul 24, 2019"
                    link="https://www.youtube.com/watch?v=kA32-FXhTfY"
                    planemodel="B737-800"
                    airline="United"
                    departure="KDCA"
                    arrival="KJFK"
                />
                <Video
                    title="X-Plane 11: SABE TO SARP (Argentina)"
                    date="Jul 23, 2019"
                    link="https://www.youtube.com/watch?v=cIxwu7iybV0"
                    planemodel="B737-800"
                    airline="Aerolíneas Argentinas"
                    departure="SABE"
                    arrival="SARP"
                />
                <Video
                    title="X-Plane 11: KATL to KFLL"
                    date="Jul 4, 2019"
                    link="https://www.youtube.com/watch?v=2qQjlP_0q_M"
                    planemodel="B737-800"
                    airline="Southwest"
                    departure="KATL"
                    arrival="KFLL"
                />
            </div>
        </div>
    );
}