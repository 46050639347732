import { latLngBounds } from "leaflet";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
const geolib = require('geolib');
var airports = require('airport-codes');

export default function Map(props) {
    const departure = props.departure;
    const arrival = props.arrival;

    const departureLatitude = airports.findWhere({ icao: departure }).get('latitude');
    const departureLongitude = airports.findWhere({ icao: departure }).get('longitude');

    const departurePosition = {latitude: departureLatitude, longitude: departureLongitude};

    const arrivalLatitude = airports.findWhere({ icao: arrival }).get('latitude');
    const arrivalLongitude = airports.findWhere({ icao: arrival }).get('longitude');

    const arrivalPosition = {latitude: arrivalLatitude, longitude: arrivalLongitude};

    const centerObject = geolib.getCenter([
        { latitude: departurePosition.latitude, longitude: departurePosition.longitude}, 
        { latitude: arrivalPosition.latitude, longitude: arrivalPosition.longitude}, 
    ]);

    const centerPosition = [centerObject.latitude, centerObject.longitude];

    const bounds = latLngBounds([[departurePosition.latitude, departurePosition.longitude], [arrivalPosition.latitude, arrivalPosition.longitude]])

    return (
        <div className="map">
            <MapContainer className="map-container" center={centerPosition} scrollWheelZoom={false} zoomControl={false} bounds={bounds.pad(0.1)}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[departurePosition.latitude, departurePosition.longitude]}>
                    <Popup>DEPARTURE AIRPORT</Popup>
                </Marker>
                <Marker position={[arrivalPosition.latitude, arrivalPosition.longitude]}>
                    <Popup>ARRIVAL AIRPORT</Popup>
                </Marker>
                <Polyline positions={[[departurePosition.latitude, departurePosition.longitude], [arrivalPosition.latitude, arrivalPosition.longitude]]} color={'purple'} />
            </MapContainer>
        </div>
    )
}