import React from "react";

export default function About() {
    return (
        <header className="about--main">
            <div className="about--title">About:</div>
            <span className="about--description">Hi! I am Andrés López Forastier. I have been running Pilot Andy since the end of my freshman year of high school. Over the years, I have virtually flown all over the world, communicating with people with different backgrounds, languages, and cultures. I first discovered flight simulation with the X-Plane 10 demo on my laptop my step-grandfather bought me when I was eight years old. From there, I was pretty much fascinated and self-taught. I watched several videos and learned air traffic, taxiing, and flight procedures. If you have any questions, feel free to email me or comment on one of my videos. Below are clickable links to my different videos, each with a route and some basic info of the flight.</span>
            <div className="about--ending">Love, Pilot Andy 🧑‍✈️</div>
        </header>
    );
}