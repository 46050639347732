import React from "react";
import About from "./components/About";
import Gallery from "./components/Gallery";
import Header from "./components/Header";

export default function App() {
    return (
        <main>
            <Header />
            <About />
            <Gallery />
        </main>
    )
}