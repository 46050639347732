import React from "react";
import Map from "./Map";

export default function Video(props) {
    const title = props.title;
    const dateString = props.date;
    const link = props.link;
    const planemodel = props.planemodel;
    const airline = props.airline;
    const departure = props.departure;
    const arrival = props.arrival;

    return (
        <div className="video--main">
            <a href={link} className="video--link">
                <div className="video--title">{title}</div>
                <div className="video--date">Date: {dateString}</div>
                <div className="video--route">
                    <Map departure={departure} arrival={arrival} />
                    <div className="video--route-dep-arr">Route: {departure} - {arrival}</div>
                </div>
                <div className="video--plane">
                    <span className="video--plane-model">Plane: {planemodel}</span>
                    <span className="video--plane-airline">Airline: {airline}</span>
                </div>
            </a>
        </div>
    )
}